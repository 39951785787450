<template>
  <div class="info-list xs">
    <div class="info-item">
      <div class="item-title nr">基本信息</div>
      <ul class="row wrap">
        <!-- <li><span>采购商编码：</span>{{companyInfo.company_abbreviation}}</li> -->
        <li><span>采购商名称：</span>{{ companyInfo.company_name }}</li>
        <li><span>采购商简称：</span>{{ companyInfo.company_abbreviation }}</li>
        <li>
          <span>采购商类型：</span
          >{{ companyInfo.company_type == 0 ? "企业" : "事业单位" }}
        </li>
        <li><span>联系人：</span>{{ companyInfo.contact }}</li>
        <li><span>手机号码：</span>{{ companyInfo.mobile }}</li>
        <li><span>固定电话：</span>{{ companyInfo.phone }}</li>
        <li><span>联系邮箱：</span>{{ companyInfo.email }}</li>
        <li class="address">
          <span>详细地址：</span>{{ companyInfo.address }}
        </li>
      </ul>
    </div>
    <div class="info-item">
      <div class="item-title nr">公司信息</div>
      <ul class="row wrap">
        <li><span>统一社会信用代码：</span>{{ companyInfo.credit_code }}</li>
        <!-- <li><span>工商注册号：</span>{{companyInfo.company_name}}</li> -->
        <!-- <li><span>登记机关：</span>{{companyInfo.company_name}}</li> -->
        <li><span>法人代表：</span>{{ companyInfo.legal_person }}</li>
        <!-- <li><span>联系人：</span>{{companyInfo.company_name}}</li> -->
        <li><span>身份证号码：</span>{{ companyInfo.id_card }}</li>
        <li><span>身份证有效期：</span>{{ companyInfo.id_card_expire }}</li>
        <li><span>注册资本：</span>{{ companyInfo.register_capital }}</li>
        <li><span>成立日期：</span>{{ companyInfo.found_date }}</li>
        <li>
          <span>营业时间：</span>{{ companyInfo.businessterm || "长期有效" }}
        </li>
        <li><span>开户名称：</span>{{ companyInfo.account_name }}</li>
        <li><span>开户银行：</span>{{ companyInfo.account_bank }}</li>
        <li><span>开户账号：</span>{{ companyInfo.account_number }}</li>
        <li>
          <span>一般纳税人资格：</span
          >{{ companyInfo.taxpayer_qualification == 1 ? "是" : "否" }}
        </li>
      </ul>
    </div>
    <div class="info-item">
      <div class="item-title nr">资质图片信息</div>
      <ul class="pic row">
        <li
          @click="handlePictureCardPreview(baseURL + companyInfo.id_card_image)"
        >
          <div class="img-box">
            <img :src="baseURL + companyInfo.id_card_image" alt="" />
          </div>
          身份证正面照
        </li>
        <li
          @click="
            handlePictureCardPreview(
              baseURL + companyInfo.business_license_image
            )
          "
        >
          <div class="img-box">
            <img :src="baseURL + companyInfo.business_license_image" alt="" />
          </div>
          营业执照副本
        </li>
        <li
          v-if="companyInfo.license_image"
          @click="handlePictureCardPreview(baseURL + companyInfo.license_image)"
        >
          <div class="img-box">
            <img :src="baseURL + companyInfo.license_image" alt="" />
          </div>
          许可证
        </li>
        <li
          @click="
            handlePictureCardPreview(baseURL + companyInfo.taxpayer_image)
          "
        >
          <div class="img-box">
            <img :src="baseURL + companyInfo.taxpayer_image" alt="" />
          </div>
          一般纳税人证
        </li>
        <!-- <li
          @click="
            handlePictureCardPreview(
              'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fwww.people.com.cn%2Fmediafile%2Fpic%2F20160120%2F68%2F14099212029166175024.jpg&refer=http%3A%2F%2Fwww.people.com.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1643970578&t=e00c3b3d5e84a9eb5db716e404733d57'
            )
          "
        >
          <div class="img-box">
            <img src="@/temp/1.jpg" alt="" />
          </div>
          许可证
        </li>
        <li
          @click="
            handlePictureCardPreview(
              'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fwww.people.com.cn%2Fmediafile%2Fpic%2F20160120%2F68%2F14099212029166175024.jpg&refer=http%3A%2F%2Fwww.people.com.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1643970578&t=e00c3b3d5e84a9eb5db716e404733d57'
            )
          "
        >
          <div class="img-box">
            <img src="@/temp/1.jpg" alt="" />
          </div>
          一般纳税人证
        </li> -->
      </ul>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,
      companyInfo: {},
      baseURL: process.env.VUE_APP_BASE_URL,
    };
  },
  computed: {},
  watch: {},
  methods: {
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file;
      this.dialogVisible = true;
    },
    getUserCenter() {
      this.$api.getUserCenterApi().then((res) => {
        if (res.code == 1) this.companyInfo = res.data.company_info;
      });
    },
  },
  created() {
    this.getUserCenter();
  },
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
.info-list {
  width: 912px;
  .info-item {
    margin-bottom: 20px;
    ul {
      margin-top: 10px;
      border: $-solid-border;
      padding: 20px;
      li {
        width: 50%;
        line-height: 2;
        &.address {
          width: 100%;
        }
        span {
          color: $-color-lighter;
        }
      }
      &.pic {
        li {
          width: 150px;
          margin-right: 30px;
          text-align: center;
          &:last-child {
            margin-right: 0;
          }
          .img-box {
            width: 150px;
            height: 150px;
            background-color: #dcdcdc;
            display: block;
            margin-bottom: 10px;
            img {
              width: auto;
              height: auto;
              max-height: 100%;
              max-width: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
